<template>
  <div
    class="i-click"
    @click="getCaptcha"
  >
    <img
      class="img"
      :src="captchaUrl"
    />
  </div>
</template>


<script>
export default {
  components: {},
  props: {
  },
  data () {
    return {
      captchaUrl: ''
    }
  },
  mounted() {
    this.getCaptcha()
  },
  computed: {},
  methods: {
    getCaptcha() {
      this.captchaUrl = '/api/site/captcha' + `?_=${+Date.now()}`
    }
  }
}
</script>
<style lang='scss' scoped>
.i-click {
  width: 96px;
  height: 34px;
  line-height: 0;
  border: 1px solid var(--color-border-primary);
  border-radius: var(--raduis-small);
  overflow: hidden;
  .img {
    object-fit: fill;
    height: 100%;
    width: 100%;
  }
}
</style>