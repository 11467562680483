<template>
  <div>
    <PlatFormLogo />
    <dl-form
      labelWidth="90px"
      ref='refForm'
      :formAttributeArr="formAttributeArr"
    >
      <template v-slot:formItem>
        <div class="click-wrap">
          <el-button
            round
            class="btn"
            type="primary"
            @click="onRegister"
          >立即注册</el-button>
          <div class="login">
            <span
              class="i-click"
              @click="handleLogin"
            >
              使用已有帐户登录
            </span>
          </div>
        </div>
      </template>
    </dl-form>

  </div>
</template>

<script>
import request from '@/utils/request'
import { CaptchaCode, SendPhoneCode } from '@/components'
import { PlatFormLogo } from './components'

export default {
  components: {
    CaptchaCode,
    SendPhoneCode,
    PlatFormLogo
  },
  data () {
    return {
      formAttributeArr: [
        {
          type: 'input',
          keyName: 'phone',
          formItem: {
            label: '手机号',
            rules: {required: true}
          },
        },
        {
          type: 'input',
          keyName: 'password',
          formItem: {
            label: '密码',
            rules: {required: true}
          },
          compAttrs: {
            showPassword: true,
          }
        },
        {
          type: 'input',
          keyName: 'captcha_code',
          formItem: {
            label: '验证码',
            rules: {required: true}
          },
          component: CaptchaCode
        },
        {
          type: 'input',
          keyName: 'verify_code',
          formItem: {
            label: '短信验证',
            rules: {required: true}
          },
          component: SendPhoneCode
        },
      ]
    }
  },
  computed: {},
  methods: {
    onRegister() {
      const {data } = this.$refs.refForm.validate()
      if (data) {
        const { phone, password, verify_code } = data
        request({
          method: 'POST',
          url: '/api/site/register_by_phone',
          data: { phone, password, verify_code },
        }).then(() => {
          this.$message.success('注册成功, 请进行登录')
          this.$emit('on-switch')
        })
      }
    },

    handleLogin() {
      this.$emit('on-switch')
    }
  }
}
</script>
<style lang='scss' scoped>
.click-wrap {
  line-height: 1;
  .btn {
    width: 100%;
    margin: 0 0 15px;
  }
  .login {
    text-align: center;
    .i-click {
      color: var(--color-text-active);
    }
  }
}
</style>