/*
 * @Description:
 * @LastEditors: LS
 * @Date: 2021-03-04 15:41:04
 */
import axios from 'axios'
import { Message } from 'element-ui'
import qs from 'qs'
import store from '../store'

const TIMEOUT = 100000

// 创建默认请求的axios实例
const service = axios.create({
  // baseURL: '/irapi',
  timeout: TIMEOUT, // 请求超时时间
  paramsSerializer: function (params) {
    const res = {}
    for (const key in params) {
      const value = params[key]
      if (value !== undefined && value !== null) {
        res[key] = value
      }
    }
    return qs.stringify(res, { indices: false })
  }
})

/**
 * 将body中参数为null的去掉
 * @param {*} service
 * @returns
 */
const setInterceptor = (service) => {
  // request拦截器
  service.interceptors.request.use(
    (config) => {
      const { data } = config
      if (data && typeof data === 'object') {
        for (const key in data) {
          if (data[key] === null) {
            delete data[key]
          }
        }
        config.data = data
      }
      return config
    },
    (error) => {
      // Do something with request error
      Promise.reject(error)
    }
  )

  // respone拦截器
  service.interceptors.response.use(
    (response) => {
      const { code, message } = response.data
      if (code === 40202) {
        store.dispatch('FeLogOut')
      }
      if (code === 40203) {
        const reg = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g
        const html = message.replace(reg, "<a href='$1$2' class='el-link el-link--primary'>$1$2</a>")

        Message({
          dangerouslyUseHTMLString: true,
          message: html,
          duration: 10 * 1000
        })
        return Promise.reject('error')
      }
      // 20000 和 0 都代表成功的意思
      if (code !== 20000 && code !== 0) {
        Message.closeAll()
        Message({
          message: message,
          type: 'error',
          duration: 2 * 1000
        })
        return Promise.reject('error')
      } else {
        return response.data
      }
    },
    (error) => {
      Message({
        message: error.message,
        type: 'error',
        duration: 2 * 1000
      })
      return Promise.reject(error)
    }
  )
  return service
}

const defaultRequest = setInterceptor(service)
const serviceNoInter = axios.create({
  timeout: 100000, // 请求超时时间
  paramsSerializer: function (params) {
    const res = {}
    for (const key in params) {
      const value = params[key]
      if (value !== undefined && value !== null) {
        res[key] = value
      }
    }
    return qs.stringify(res, { indices: false })
  }
})

export default defaultRequest
export { serviceNoInter }
