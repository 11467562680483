<template>
  <div class="i-link footer">
    <a
      href='https://beian.miit.gov.cn/#/Integrated/index'
      class="link"
      target="_blank"
    >沪ICP 备2020031644号-3</a>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
  },
  data () {
    return {

    }
  },
  computed: {},
  methods: {}
}
</script>
<style lang='scss' scoped>
.footer {
  text-align: center;
  padding: 10px 0 20px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #bbb;
  .link {
    color: inherit;
    text-decoration: none;
  }
}
</style>