<template>
  <div style="width:140px;">
    <div
      class="tabs flex"
      v-for="tab in tabs"
      :key="tab.id"
      :class="{active: tab.active}"
      @click="onTabSelect(tab)"
    >
      <i :class="tab.icon"></i>
      <span class="tabs--label flex--1 p8-left">{{tab.label}}</span>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
  },
  data () {
    return {
      tabs: [
        {
          id: 0,
          label: '平台自选',
          icon: 'ri-home-heart-line',
          active: false,
        },
        {
          id: 1,
          label: '我的自选',
          icon: 'ri-home-heart-line',
          active: false,
        },
      ]
    }
  },
  computed: {},
  methods: {
    onTabSelect(payload) {
      this.tabs = this.tabs.map((tab) => ({
        ...tab,
        active: tab.id === payload,
      }))
    }
  }
}
</script>
<style lang='scss' scoped>
.tabs {
  padding: 0 16px;
  color: var(--text-secondary);
  line-height: 36px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover,
  &.active {
    color: var(--el-active);
    box-shadow: inset 2px 0 0 0 var(--el-active);
  }
}
</style>