<!--
 * @Description:
 * @Date: 2021-05-26 17:26:59
-->
<template>
  <div class="code-comp">
    <el-input
      class="input-wrap"
      v-model="inputValue"
      @blur="blurInput"
      placeholder="请输入验证码"
    ></el-input>
    <CodeImg />
  </div>
</template>

<script>
import CodeImg from './code-img.vue'
export default {
  components: {
    CodeImg
  },
  props: {
    data: {
      type: Object,
      require: false
    },
    getFormData: Function
  },
  data () {
    return {
      inputValue: ''
    }
  },
  mounted() {
  },
  methods: {
    blurInput() {
      this.updateValueToForm()
    },
    // data对应的就是formData里面的数据，所以需要出发updateValue
    updateValueToForm() {
      if (this.data) {
        this.$emit('updateValue', this.data.keyName, this.inputValue, 'custom')
      }
    }
  },
}
</script>
<style lang='scss' scoped>
.code-comp {
  display: flex;
  .input-wrap {
    flex: 1;
    height: 36px;
    padding-right: 10px;
  }
}
</style>
