import Vue from 'vue'
import Router from 'vue-router'
import Store from '@/store'
Vue.use(Router)

// 基础的路由，每个都需要的
const commonRouter = [
  {
    path: '/home/person',
    name: 'person-detail',
    hidden: true,
    component: () => import('@/views/account-pages/person-detail/index'),
    meta: { title: '个人信息' }
  },
  {
    path: '/home/forget',
    name: 'forget',
    hidden: true,
    component: () => import('@/views/account-pages/forget-password/index1'),
    meta: { title: '密码重置' }
  },
  {
    path: '/search/report',
    name: 'search',
    hidden: false,
    component: () => import('@/views/search/report-search/index'),
    meta: { title: '看研报' }
  },
  {
    path: '/pdf-img/view',
    name: 'pdf-img',
    hidden: true,
    component: () => import('@/views/search/pdf-view/index'),
    meta: { title: 'PDF查看' }
  },
  {
    path: 'https://tushare.pro/',
    name: '',
    hidden: false,
    component: null,
    meta: { title: '数据API' }
  },
  // 优先级最低，其他都匹配不上之后，重定向到/
  {
    path: '*',
    hidden: true,
    redirect: '/search/report'
  }
  // {
  //   path: '/home/optional',
  //   name: 'unkonwn',
  //   hidden: false,
  //   component: () => import('@/views/choose-stock/platform'),
  //   meta: { title: '图片搜索' }
  // },
]

export default async function setRouter() {
  const router = new Router({
    mode: 'hash',
    linkActiveClass: 'link-active',
    routes: commonRouter
  })
  try {
    await Store.dispatch('GetInfo')
  } catch (e) {
    //
  }
  return router
}
