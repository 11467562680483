<template>
  <div>
    <img
      class="logo"
      :src="require('@/assets/imgs/login_logo.png')"
      alt=""
    >
  </div>
</template>

<script>
export default {
  components: {},
  props: {
  },
  data () {
    return {

    }
  },
  computed: {},
  methods: {}
}
</script>
<style lang='scss' scoped>
.logo {
  display: block;
  margin: 0 auto 30px;
  height: 60px;
}
</style>