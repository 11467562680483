<template>
  <div
    class="u-sec f-ellipsis2 text"
    v-if="text"
  >
    【摘要】：{{text}}
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    }
  },
  data () {
    return {

    }
  },
  mounted() {
  },
  computed: {},
  methods: {}
}
</script>
<style lang='scss' scoped>
.text {
  text-indent: 2em;
  position: relative;
}
</style>