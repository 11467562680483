<template>
  <div class="page">
    <HomeHeader></HomeHeader>
    <div class="page-content">
      <router-view></router-view>
    </div>
    <HomeFooter />
  </div>
</template>

<script>
import HomeHeader from '@/views/basic/header.vue'
import HomeFooter from '@/views/basic/footer.vue'
export default {
  name: 'App',
  components: {
    HomeHeader,
    HomeFooter
  },
  created() {
  },
  methods: {

  }
}
</script>

<style lang='scss' scoped>
.page {
  min-width: 900px;
  .page-content {
    width: 80%;
    margin: 30px auto;
  }
}
</style>
