<template>
  <div class="text-wrap">
    <div
      :class="{'u-title f-ellipsis2': true, 'i-link': clickTitle}"
      @click="clickTitle"
    >
      {{fileInfo.title}}<span
        class="tag"
        v-if="fileInfo.report_label"
      >{{fileInfo.report_label}}</span>
    </div>
    <div class="u-regular addition-info">
      <img
        class="icon"
        :src="require('@/assets/imgs/company.svg')"
        alt="pdf"
      >
      {{fileInfo.report_source}} {{fileInfo.author}}

      <img
        class="icon"
        :src="require('@/assets/imgs/category.svg')"
        alt="category"
      >
      {{fileInfo.category_name}}
      <span
        class="point"
        v-if="fileInfo.category_name && fileInfo.industry_name"
      >·</span>
      {{fileInfo.industry_name}}

      <img
        class="icon"
        :src="require('@/assets/imgs/time.svg')"
        alt="pdf"
      >
      {{fileInfo.publish_dt}}

      <img
        class="icon"
        :src="require('@/assets/imgs/pdf.svg')"
        alt="pdf"
      >
      {{fileInfo.file_page}}页

    </div>
    <ExpandEllipsis
      v-if='showSummary'
      :text="fileInfo.summary"
    ></ExpandEllipsis>
  </div>
</template>

<script>
import { ExpandEllipsis } from '@/components'
export default {
  components: {
    ExpandEllipsis
  },
  props: {
    showSummary: Boolean,
    fileInfo: Object,
    clickTitle: Function
  },
  data () {
    return {
    }
  },
  computed: {},
  methods: {
    // clickTT() {
    //   this.$emit('click-title')
    // }
  }
}
</script>
<style lang='scss' scoped>
.text-wrap {
  position: relative;
  flex: 1;
  overflow: hidden;

  .u-title {
    .pdf-icon {
      width: 20px;
      vertical-align: middle;
    }
  }
  .tag {
    display: inline-block;
    font-size: 12px;
    line-height: 16px;
    padding: 1px 5px;
    background: #f5e2bd;
    color: #9a612d;
    border-radius: var(--raduis-small);
    transform: scale(0.8);
    vertical-align: text-bottom;
  }
  .addition-info {
    display: flex;
    margin: 10px 0;
    .icon {
      width: 14px;
      margin-left: 20px;
      margin-right: 5px;
      &:first-child {
        margin-left: 0;
      }
    }
    .point {
      padding: 0 5px;
      font-weight: 900;
    }
  }
}
</style>