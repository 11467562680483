<template>
  <div class="login-comp">
    <PlatFormLogo />
    <dl-form
      ref='refForm'
      labelWidth="70px"
      :formAttributeArr="formAttributeArr"
      :defaultFormData="defaultFormData"
    >
      <template v-slot:formItem>
        <div class="click-wrap">
          <div class="operate-wrap">
            <span></span>
            <span
              class="u-regular-s i-link"
              @click="onForget"
            >忘记密码?</span>
          </div>
          <el-button
            round
            class="login-btn"
            type="primary"
            @click="handleLogin"
          >登录</el-button>
          <div class="register">
            <span
              class="i-click"
              @click="handleRegister"
            >
              注册账户
            </span>
          </div>
        </div>
      </template>
    </dl-form>
  </div>

</template>

<script>
import { PlatFormLogo } from './components'
import { CaptchaCode } from '@/components'
export default {
  components: {
    CaptchaCode,
    PlatFormLogo
  },
  props: {
    visibleNum: Number,
  },
  data () {
    return {
      defaultFormData: {
        // username: '18052222407',
        // password: 'Dayan@123',
        // username: '13051875252',
        // password: '123456',
        username: '',
        password: '',
      },
      formAttributeArr: [
        {
          type: 'input',
          keyName: 'username',
          formItem: {
            label: '用户名',
            rules: {required: true}
          },
        },
        {
          type: 'input',
          keyName: 'password',
          formItem: {
            label: '密码',
            rules: {required: true}
          },
          compAttrs: {
            showPassword: true,
          }
        },
        {
          type: 'input',
          keyName: 'captcha_code',
          formItem: {
            label: '验证码',
            rules: {required: true}
          },
          component: CaptchaCode
        },
      ]
    }
  },
  computed: {},
  methods: {
    handleLogin() {
      const { data } = this.$refs.refForm.validate()
      if (data) {
        this.$store.dispatch('Login', data).then(res => {
          this.$emit('on-close')
          // const url = this.$route.path
          window.location.reload()
        })
      }
    },

    handleRegister() {
      this.$emit('on-switch')
    },

    onForget() {
      this.$emit('on-close')
      window.location.href = '#/home/forget'
    }
  }
}
</script>
<style lang='scss' scoped>
.login-comp {
  .click-wrap {
    line-height: 1;
    .operate-wrap {
      display: flex;
      justify-content: space-between;
    }

    .login-btn {
      width: 100%;
      margin: 22px 0 15px;
    }
    .register {
      text-align: center;
      .i-click {
        color: var(--color-text-active);
      }
    }
  }
}
</style>