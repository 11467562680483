<template>
  <dl-dialog
    title="修改密码"
    width="500px"
    :visibleNum="visibleNum"
    :confirmPromise="confirmPromise"
  >
    <dl-form
      ref='dataForm'
      :key="formKey"
      label-width='100px'
      :formAttributeArr="formArr"
      :defaultFormData="dataForm"
    ></dl-form>
  </dl-dialog>
</template>
<script>
import { request } from '@/utils'
export default {
  props: {
    visibleNum: Number
  },
  watch: {
    visibleNum() {
      this.formKey++
    }
  },
  data() {
    const validatePass = (rule, value, callback) => {
      const { new_password } = this.$refs.dataForm.formData
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== new_password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      formKey: 0,
      dataForm: {},
      formArr: [
        {
          formItem: {
            label: '原密码',
            rules: { required: true, message: '请输入旧密码', trigger: 'blur' }
          },
          type: 'input',
          keyName: 'old_password',
          compAttrs: {
            showPassword: true,
          }
        },
        {
          formItem: {
            label: '新密码',
            rules: [
              { required: true, message: '请输入新密码', trigger: 'blur' },
            ],
          },
          type: 'input',
          keyName: 'new_password',
          compAttrs: {
            showPassword: true,
          }
        },
        {
          formItem: {
            label: '确认密码',
            rules: [
              { required: true, trigger: 'blur', validator: validatePass },
            ]
          },
          type: 'input',
          keyName: 'confimPassword',
          compAttrs: {
            showPassword: true,
          }
        },
      ]
    }
  },
  methods: {
    confirmPromise(data) {
      return request({
        url: '/api/users/password',
        method: 'patch',
        data: {
          new_password: data.new_password,
          old_password: data.old_password,
        },
      }).catch(() => {
        return Promise.reject()
      })
    }
  }
}
</script>
<style scoped>
</style>