<template>
  <div
    class="user-img-comp__scope"
    :style="{width: width, height: height}"
  >
    <el-image
      class="image"
      :width='width'
      :src="url"
    >
      <div
        slot="error"
        class="image-slot"
      >
        <img
          class="image"
          :src="require('./img/default.svg')"
          alt=""
        >
      </div>
    </el-image>
  </div>
</template>

<script>

export default {
  components: {},
  props: {
    width: {
      type: String,
    },
    height: {
      type: String,
    }
  },
  data () {
    return {
      imgUrl: '',
    }
  },
  computed: {
    url() {
      return this.$store.state.user.info.image_url
    },
  },
  methods: {
  }
}
</script>
<style lang='scss' scoped>
.user-img-comp__scope {
  display: inline-block;
  border: 1px solid var(--color-border-sec);
  border-radius: 50%;
  overflow: hidden;

  .image {
    width: 100%;
    height: 100%;
    /deep/ .image-slot {
      width: 100%;
      height: 100%;
      .image {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
<style >
.i-click-cn:hover .user-img-comp__scope {
  border: 1px solid var(--color-text-active);
}
</style>