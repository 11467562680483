import request, { serviceNoInter } from '@/utils/request'

const user = {
  state: {
    name: '',
    info: {},
    logined: true,
    showLoginNum: 0
  },

  mutations: {
    SET_INFO: (state, info) => {
      state.info = info || {}
    },
    SET_LOGINED: (state, logined) => {
      state.logined = logined
    },
    SET_LOGIN_FORM_SHOW: (state, loginNum) => {
      state.showLoginNum = loginNum
    },
    SET_IMAGE_URL: (state, imgUrl) => {
      state.info = {
        ...state.info,
        image_url: imgUrl
      }
    }
  },

  actions: {
    // 登录
    Login({ commit }, data) {
      return new Promise((resolve, reject) => {
        request({
          method: 'POST',
          url: '/api/site/login',
          data
        })
          .then((response) => {
            const data = response.data
            commit('SET_INFO', data)
            commit('SET_LOGINED', true)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        request({
          url: '/api/users/state',
          method: 'get'
        })
          .then((response) => {
            const { data } = response
            commit('SET_INFO', data)
            commit('SET_LOGINED', true)
            resolve(response)
          })
          .catch((error) => {
            commit('SET_INFO', {})
            commit('SET_LOGINED', false)
            reject(error)
          })
      })
    },
    // 登出
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        request({
          method: 'POST',
          url: '/api/site/logout'
        })
          .then(() => {
            commit('SET_INFO', {})
            commit('SET_LOGINED', false)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 修改头像
    ChangeImageUrl({ commit }, data) {
      commit('SET_IMAGE_URL', data)
    },
    // 前端 登出 将数据清空
    FeLogOut({ commit }) {
      return new Promise((resolve) => {
        commit('SET_INFO', {})
        commit('SET_LOGINED', false)
        resolve()
      })
    },
    ShowLoginDialog({ commit, state }) {
      commit('SET_LOGIN_FORM_SHOW', state.showLoginNum + 1)
    }
  }
}

export default user
