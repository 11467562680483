<template>
  <div class="page-header">
    <div class="left">
      <img
        class="logo"
        :src="require('@/assets/imgs/login_logo.png')"
      />
      <Navigation></Navigation>
    </div>
    <UserInfo></UserInfo>
  </div>
</template>

<script>
import UserInfo from './user-info'
import Navigation from './page-navigation'
export default {
  components: {
    UserInfo,
    Navigation
  },
  props: {
  },
  data () {
    return {
    }
  },
  computed: {},
  methods: {
  }
}
</script>
<style lang='scss' scoped>
.page-header {
  // padding: 0 120px;
  padding: 0 8%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  background-color: var(--color-bg-cn);
  .left {
    display: flex;
    align-items: center;

    .logo {
      height: 30px;
      padding-right: 40px;
    }
  }
}
</style>