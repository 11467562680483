<template>
  <div class="user-comp">
    <div
      v-if="logined"
      class="user-wrap"
    >
      <el-dropdown class="">
        <span class="i-click-cn user-info">
          <UserImg
            width='22px'
            height='22px'
          />
          <span class="user i-click">
            {{userName}}
          </span>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>
            <div @click="skipToPersonPage">个人信息</div>
          </el-dropdown-item>
          <el-dropdown-item>
            <div @click="changePassword">修改密码</div>
          </el-dropdown-item>
          <el-dropdown-item>
            <div @click="onLogout">退出登录</div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <TabList
      v-else
      :defaultActiveTab="formType"
      @changeActiveTab="showDialog"
      :tabList="[{value: 'login', label: '登录'},{value: 'register', label: '注册'}]"
    ></TabList>
    <dl-dialog
      ref='dialog'
      width="580px"
      :visibleNum="visibleNum"
      type='no-close'
      @close="closeCb"
    >
      <LoginForm
        class="form"
        v-if="formType === 'login'"
        @on-switch="formType = 'register'"
        @on-close="closeDialog"
      ></LoginForm>
      <RegisterForm
        class="form"
        v-else-if="formType === 'register'"
        @on-switch="formType = 'login'"
      ></RegisterForm>
    </dl-dialog>
    <ChangePassword :visibleNum="changePassNum" />
  </div>
</template>

<script>
import LoginForm from './login-form.vue'
import RegisterForm from './register-form.vue'
import ChangePassword from './change-password.vue'
import { TabList, UserImg } from '@/components'
export default {
  components: {
    RegisterForm,
    LoginForm,
    ChangePassword,
    UserImg,
    TabList
  },
  data () {
    return {
      visibleNum: 0,
      changePassNum: 0,
      formType: '',
    }
  },
  computed: {
    logined() {
      return this.$store.state.user.logined
    },
    userName() {
      const { phone, nickname } = this.$store.state.user.info
      if (nickname) {
        return nickname
      } else if (phone) {
        return `${phone.slice(0, 3)}***`
      }
      return ''
    }
  },
  methods: {
    showDialog(type) {
      this.visibleNum++
      this.formType = type
    },
    closeCb() {
      this.$emit('on-close')
      setTimeout(() => {
        this.formType = ''
      }, 100)
    },
    closeDialog() {
      this.$refs.dialog.handleClose()
      this.closeCb()
    },
    changePassword() {
      this.changePassNum++
    },
    skipToPersonPage() {
      this.$router.push({name: 'person-detail'})
    },
    onLogout() {
      this.$store.dispatch('LogOut').then(() => {
        this.$message.success('已经退出登陆')
        // 重定向到login页面
        window.location.reload('/')
      })
    },
    delayShowLoginDialog() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.showDialog('login')
        }, 200)
      })
    }
  },
  watch: {
    logined: {
      handler(logined) {
        if (!logined) {
          this.delayShowLoginDialog()
        }
      },
      immediate: true,
    },
    '$store.state.user.showLoginNum'() {
      this.delayShowLoginDialog()
    }
  }
}
</script>
<style lang='scss' scoped>
.form {
  width: 370px;
  margin: auto;
}
.user-info {
  display: flex;
  justify-content: center;
  align-items: center;
  .user {
    margin-left: 10px;
  }
}
</style>