import Vue from 'vue'
import App from './App.vue'
import Router from './router'
import store from './store'

// 全局引用element UI
import Element from 'element-ui'
import locale from 'element-ui/lib/locale/lang/zh-CN'
import 'element-ui/lib/theme-chalk/index.css'

// 自己的业务组件
import DLUI from '@dl-components/dl-ui'
import '@dl-components/dl-ui/lib/styles/dl-ui.css'
import '@/assets/styles/index.scss'

Vue.use(DLUI)
Vue.use(Element, { locale })

Vue.config.productionTip = false

Router().then((router) => {
  new Vue({
    el: '#app',
    router,
    store,
    render: (h) => h(App)
  })
})
