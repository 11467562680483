<template>
  <span class="tab-comp">
    <span
      v-for="tab in tabList"
      :key="tab.value"
      :class="{'u-title tab': true, 'active-tab': activeTab === tab.value, 'i-click': true}"
      @click="changeActiveTab(tab.value)"
    >{{tab.label}}</span>
  </span>
</template>

<script>
export default {
  components: {},
  props: {
    // {label, value}
    tabList: Array,
    defaultActiveTab: String
  },
  data () {
    return {
      activeTab: this.defaultActiveTab || ''
    }
  },
  computed: {},
  methods: {
    changeActiveTab(tab) {
      this.activeTab = tab
      this.$emit('changeActiveTab', tab)
    }
  },
  watch: {
    defaultActiveTab() {
      this.activeTab = this.defaultActiveTab
    }
  }
}
</script>
<style lang='scss' scoped>
.tab-comp {
  display: inline-block;
  .tab {
    display: inline-block;
    font-weight: normal;
    margin-left: 18px;
  }
  .active-tab {
    color: var(--color-text-active);
    font-weight: 500;
  }
}
</style>