<!--
 * @Description:
 * @Date: 2021-05-26 17:26:59
-->
<template>
  <div class="code-comp">
    <el-input
      class="input-wrap"
      v-model="inputValue"
      @blur="blurInput"
      placeholder="请输入短信验证码"
    ></el-input>
    <el-button
      type="primary"
      class="btn"
      @click="sendCode"
      :disabled="disabled"
    >{{label}}</el-button>
    <!-- <CodeBtn /> -->
  </div>
</template>

<script>
import CodeBtn from './code-button.vue'
import request from '@/utils/request'

export default {
  components: {
    CodeBtn
  },
  props: {
    // formData里面的数据
    data: {
      type: Object,
      require: false
    },
    getFormRef: Function,
    getFormData: Function,
  },
  data () {
    return {
      inputValue: '',
      disabled: false,
      label: '获取验证码',
      formRef: null,
    }
  },
  methods: {
    startCount() {
      this.disabled = true
      let count = 60

      const timer = setInterval(() => {
        this.label = `${count--}秒`
        if (count <= 0) {
          clearInterval(timer)
          this.disabled = false
          this.label = '获取验证码'
        }
      }, 1000)
    },

    sendCode() {
      const { ensure_user_exists = false } = this.data.compAttrs || {}
      if (!this.formRef) {
        this.formRef = this.getFormRef()
      }
      this.formRef.validateField('phone')
      this.formRef.validateField('captcha_code')
      const { phone, captcha_code } = this.getFormData()
      if (phone && captcha_code) {
        request({
          method: 'POST',
          url: 'api/site/send_phone_verify_code',
          data: { phone, captcha_code, ensure_user_exists },
        }).then(() => {
          this.$message.success('短信验证码已发送！')
          this.startCount()
        })
      }
    },

    blurInput() {
      this.updateValueToForm()
    },
    // data对应的就是formData里面的数据，所以需要出发updateValue
    updateValueToForm() {
      if (this.data) {
        this.$emit('updateValue', this.data.keyName, this.inputValue, 'custom')
      }
    }
  },
}
</script>
<style lang='scss' scoped>
.code-comp {
  display: flex;
  .input-wrap {
    height: 36px;
    padding-right: 10px;
  }
  .btn {
    padding: 10px 12px;
    min-width: 96px;
  }
}
</style>
