<template>
  <el-menu
    :default-active="$route.path"
    :router="true"
    class="el-menu-nav"
    mode="horizontal"
  >
    <el-menu-item
      class="nav-item"
      v-for="route in routers"
      :key="route.path"
      :index="route.path"
      @click="changeActiveNav(route)"
    >
      {{route.meta.title}}
    </el-menu-item>
  </el-menu>
</template>

<script>
export default {
  components: {},
  props: {
  },
  data () {
    return {
      defaultActive: this.$route.path,
      key: 0,
    }
  },
  mounted() {
    this.defaultActive = this.$route.path
  },
  computed: {
    routers() {
      return this.$router.options.routes.filter((router) => !router.hidden)
    },
  },
  methods: {
    changeActiveNav(route) {
      const { component } = route
      // 如果没有component，说明没有path，进行链接跳转
      if (!component) {
        window.open(route.path, '_blank')
      }
    },
  }
}
</script>
<style lang='scss' scoped>
.el-menu-nav {
  .nav-item {
    font-size: 16px;
    height: 50px;
    line-height: 50px;
    margin-right: 40px;
    padding: 0;
  }
}
</style>