<template>
  <el-button
    type="primary"
    class="btn"
    @click="sendCode"
    :disabled="disabled"
  >{{label}}</el-button>
</template>

<script>
export default {
  components: {},
  props: {
    onSend: Function
  },
  data () {
    return {
      disabled: false,
      label: '获取验证码'
    }
  },
  computed: {},
  methods: {
    startCount() {
      this.disabled = true
      let count = 60
      // this.sendCode()
      const timer = setInterval(() => {
        this.label = `获取验证码(${count--}秒)`
        if (count <= 0) {
          clearInterval(timer)
          this.disabled = false
          this.label = '获取验证码'
        }
      }, 1000)
    },

    sendCode() {
      this.onSend().then((success) => {
        console.log('success: ', success)
      })
    }
  }
}
</script>
<style lang='scss' scoped>
.btn {
  padding: 10px 12px;
}
</style>