<template>
  <span class="tt-comp">
    <span :class="{content: true, tt: text}">
      {{text}}
      <slot></slot>
    </span>
  </span>
</template>

<script>
export default {
  components: {},
  props: {
    text: String
  },
  data () {
    return {

    }
  },
  computed: {},
  methods: {}
}
</script>
<style lang='scss' scoped>
.tt-comp {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  &::before {
    content: '';
    position: absolute;
    top: 0px;
    bottom: 0px;
    margin: auto;
    display: inline-block;
    width: 4px;
    border-radius: 1px;
    background: #589ef8;
    height: 16px;
  }
  .content {
    display: inline-block;
  }
  .tt {
    color: var(--color-text-primary);
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    margin-left: 14px;
  }
}
</style>